<template>
  <div class="project-root-container">
    <div class="project-header-container">
      <div class="project-header-left-container">
        <span class="project-header-title">项目详情</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="project-arrow" />
    </div>
    <div class="project-main-container">
      <div class="project-scroll-wrapper">
        <div class="project-top-container">
          <span style="font-size: 32px; font-weight: bold">{{
            projectName
          }}</span>
          <div class="project-state-container">
            <Icon type="md-lock" color="red" />
            <span style="margin-left: 10px; font-size: 13px; font-weight: bold"
              >非公开项目</span
            >
          </div>
        </div>

        <div class="project-tab-container">
          <div
            @click="tabChanged(1)"
            :class="[
              currentTab == 1
                ? 'project-tab-item-selected'
                : 'project-tab-item-normal',
            ]"
          >
            基本信息
          </div>
          <div
            @click="tabChanged(2)"
            :class="[
              currentTab == 2
                ? 'project-tab-item-selected'
                : 'project-tab-item-normal',
            ]"
          >
            项目成员
          </div>
        </div>

        <transition name="fade" mode="out-in">
          <router-view class="project-router-container" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      icons: icon,
      projectId: 0,
      projectName: "",
      currentTab: 1,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.getProjectInfo();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == "project-base") {
      this.currentTab = 1;
    } else if (to.name == "project-invite") {
      this.currentTab = 2;
    }
    next();
  },
  methods: {
    ...mapActions({
      projectGetBasicAction: "projectGetBasic",
    }),
    tabChanged(tab) {
      if (this.currentTab == tab) {
        return;
      }
      this.currentTab = tab;
      switch (this.currentTab) {
        case 1:
          this.$router.push({
            name: "project-base",
            params: {
              id: this.projectId,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "project-invite",
            params: {
              id: this.projectId,
            },
          });
          break;
      }
    },
    close() {
      this.$router.push({ name: "home" });
    },
    getProjectInfo() {
      this.projectGetBasicAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          this.projectName = res.name;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
      switch (this.$router.currentRoute.name) {
        case "project-base":
          this.currentTab = 1;
          break;
        case "project-invite":
          this.currentTab = 2;
          break;
      }
    },
  },
};
</script>

<style scoped>
.project-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow: hidden;
}
.project-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.project-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.project-header-title {
  margin-left: 40px;
  color: #fff;
  font-size: 15px;
}
.project-main-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.project-scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 50px 100px;
  overflow-y: scroll;
  z-index: 2;
}
.project-top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-state-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  padding: 10px 20px;
  border-radius: 4px;
  margin-left: 20px;
  cursor: pointer;
  user-select: none;
}
.project-tab-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  background-color: #313f4a;
  border-radius: 22px;
}
.project-tab-item-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 112px;
  color: #0a0a0a;
  background-color: #ffffff;
  border-radius: 14px;
  cursor: pointer;
  margin: 0px 30px;
}
.project-tab-item-normal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 112px;
  color: #ffffff;
  cursor: pointer;
  margin: 0px 30px;
}
.project-router-container {
  flex: 1;
  overflow: hidden;
}
</style>